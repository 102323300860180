 .chatui {
   display: flex;
   flex-flow: column wrap;
   justify-content: space-between;
   width: 100%;
   max-width: 867px;
   margin: 0px auto;
   height: 100%;
   border-radius: 5px;
   /* box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2); */
 }

 s .chatui-header {
   display: flex;
   justify-content: space-between;
   padding: 10px;
   border-bottom: 2px solid #ddd;
   background: #eee;
   color: #666;
 }

 .chatui-chat {
   flex: 1;
   overflow-y: auto;
   padding: 10px;

 }

 .chatui-chat::-webkit-scrollbar {
   width: 6px;
 }

 .chatui-chat::-webkit-scrollbar-track {
   background: #ddd;
 }

 .chatui-chat::-webkit-scrollbar-thumb {
   background: #bdbdbd;
 }

 .msg {
   display: flex;
   align-items: flex-end;
   margin-bottom: 10px;
   width: 100%;
 }

 .msg:last-of-type {
   margin: 0;
 }

 .msg-bubble {
   max-width: 450px;
   padding: 5px;
   border-radius: 15px;
   background: #fff;
   color: gray;
   display: inline-flex;
 }

 .left-msg .msg-bubble {
   text-align: left;
   border-bottom-left-radius: 0;
 }

 .error-msg .msg-bubble {
   border-bottom-left-radius: 0;
   color: #f15959;
 }

 .init-msg .msg-bubble {
   border-bottom-left-radius: 0;
 }

 .right-msg {
   flex-direction: row-reverse;
 }

 .right-msg .msg-bubble {
   background: #fff;
   color: gray;
   border-bottom-right-radius: 0;
 }

 .chatui-inputarea {
   display: flex;
   /* padding: 10px; */
   margin-top: 20px;
   /* border-top: 2px solid #ddd; */
   /* background: #eee; */
   height: 65px;
 }

 .chatui-inputarea * {
   /* padding: 10px; */
   border: none;
   border-radius: 3px;
   font-size: 1em;
 }

 .chatui-input {
   background: rgb(33 33 33);
 }

 .chatui-reset-btn {
   margin-left: 10px;
   background: #ececec;
   font-weight: bold;
   border-radius: 8px;
   width: 200px;
   cursor: pointer;
 }

 .chatui-reset-btn:hover {
   background: #dcdada;
 }

 .chatui-send-btn {

   background: rgb(33 33 33);
   color: #fff;
   font-weight: bold;
   cursor: pointer;
   margin: 0 0px 0px 20px;
 }

 .chatui-send-btn:hover {
   background: #444646;
 }

 .chatui-chat {
   background-color: rgb(146 146 146);
 }


 canvas {
   display: block;
   margin: 0 auto;
 }

 .dialog {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-color: #fff;
   padding: 20px;
   border-radius: 10px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
 }

 button {
   display: block;
   margin: 10px auto;
   padding: 5px 10px;
   border: none;
   background-color: #ccc;
   border-radius: 5px;
   cursor: pointer;
 }

 .bg-blue-500 {
   background-color: #3b82f6;
   /* 设置进度条颜色 */
 }